import { defineMessages } from 'react-intl';

export default defineMessages({
  msgJobGroupName: {
    id: 'newSchedule.stepJobGroup.name',
    defaultMessage: 'Design the Job Group you want to schedule',
  },
  msgJobGroupDescription: {
    id: 'newSchedule.stepJobGroup.description',
    defaultMessage:
      'Add the Jobs you want to execute and create dependencies by connecting them',
  },
  msgStepPeriodName: {
    id: 'newSchedule.stepPeriod.name',
    defaultMessage: 'Set the Period for the Schedule',
  },
  msgStepPeriodDescription: {
    id: 'newSchedule.stepPeriod.description',
    defaultMessage:
      'The period defines when and how often the schedule is triggered',
  },
  //NewScheduleWizard
  msgFieldNameName: {
    id: 'orchestration.add_edit_job_group.field_name.name',
    defaultMessage: 'Name',
  },
  msgFieldNameDescription: {
    id: 'orchestration.add_edit_job_group.field_name.description',
    defaultMessage: 'Enter a name for the Job Group',
  },
  msgFieldDescriptionName: {
    id: 'orchestration.add_edit_job_group.field_description.name',
    defaultMessage: 'Description',
  },
  msgFieldDescriptionDescription: {
    id: 'orchestration.add_edit_job_group.field_description.description',
    defaultMessage: 'Enter a description for the Job Group',
  },
  addEditJobGroupRepositoryErrorNoExecutionType: {
    id: 'orchestration.add_edit_job_group.repository.error.no_execution_type',
    defaultMessage: 'Unavailable. Execution Type not found.',
  },
  msgConfirmationUpdate: {
    id: 'orchestration.newSchedule.confirmation.update',
    defaultMessage: 'The Schedule has been updated',
  },
  msgConfirmationSetup: {
    id: 'orchestration.newSchedule.confirmation.setup',
    defaultMessage: 'The Schedule has been set up',
  },
  scheduleDescriptionDelayed: {
    id: 'orchestration.schedule.description.delayed',
    defaultMessage: 'Runs at <b>{date}</b>',
  },
  scheduleDescriptionPeriodMonthly: {
    id: 'orchestration.schedule.description.period_monthly',
    defaultMessage:
      'Runs the <b>{dayOfMonth}</b> of every month at <b>{time}</b>',
  },
  scheduleDescriptionPeriodWeekly: {
    id: 'orchestration.schedule.description.period_weekly',
    defaultMessage: 'Runs every <b>{dayOfWeek}</b> at <b>{time}</b>',
  },
  scheduleDescriptionPeriodDaily: {
    id: 'orchestration.schedule.description.period_daily',
    defaultMessage: 'Runs every day at <b>{time}</b>',
  },
  scheduleDescriptionPeriodHourly: {
    id: 'orchestration.schedule.description.period_hourly',
    defaultMessage: 'Runs every hour at <b>{minuteOfHour}</b>',
  },
  msgCodeCapsuleOutputToggle: {
    id: 'orchestration.code.capsule.output.toggle',
    defaultMessage: 'Auto Refresh',
  },
  msgCodeCapsuleOutputTimeFinished: {
    id: 'orchestration.code.capsule.output.time.finished',
    defaultMessage: 'Started: {started}, Ended: {ended} (Duration: {duration})',
  },
  msgCodeCapsuleOutputTimeInProgress: {
    id: 'orchestration.code.capsule.output.time.inProgress',
    defaultMessage:
      'Started: {started}, Status: {status} (Duration: {duration})',
  },
  jobDetailsEventsHeaderType: {
    id: 'orchestration.job_details.events.header.type',
    defaultMessage: 'Type',
  },
  jobDetailsEventsHeaderReason: {
    id: 'orchestration.job_details.events.header.reason',
    defaultMessage: 'Reason',
  },
  jobDetailsEventsHeaderAge: {
    id: 'orchestration.job_details.events.header.age',
    defaultMessage: 'Age',
  },
  jobDetailsEventsHeaderMessage: {
    id: 'orchestration.job_details.events.header.message',
    defaultMessage: 'Message',
  },
  jobDetailsEventsAge: {
    id: 'orchestration.job_details.events.cell.age',
    defaultMessage:
      '{lastTimestamp} ({count} times since first seen {firstTimestamp})',
  },

  jobGroupsDeleteModalDescription: {
    id: 'orchestration.job_groups.delete_modal.description',
    defaultMessage:
      'You are about to delete this Job Group. Please confirm that you are sure.',
  },
  jobGroupsDeleteModalHeadline: {
    id: 'orchestration.job_groups.delete_modal.headline',
    defaultMessage: 'Delete Job Group',
  },

  jobDetailsCancelButtonTitle: {
    id: 'orchestration.job.details.button.cancel.title',
    defaultMessage: 'Logs',
  },
  jobDetailsTitle: {
    id: 'orchestration.job.details.title',
    defaultMessage: 'Job Details',
  },
  jobKubernetesEventsTitle: {
    id: 'orchestration.job.kubernetes_events.title',
    defaultMessage: 'Kubernetes Events',
  },

  jobGroupDetailsTitle: {
    id: 'orchestration.job_group.details.title',
    defaultMessage: 'Job Group Details',
  },
  jobGroupDetailsTopologyTitle: {
    id: 'orchestration.job_group.details.topology.title',
    defaultMessage: 'Job Group Topology',
  },
  detailsValueNoValue: {
    id: 'orchestration.details.value.no_value',
    defaultMessage: 'No value defined',
  },

  jobQueueOrigin: {
    id: 'orchestration.job_queue.origin',
    defaultMessage: 'Job Queue',
  },
  jobHistoryOrigin: {
    id: 'orchestration.job_history.origin',
    defaultMessage: 'Job History',
  },
  jobGroupsOrigin: {
    id: 'orchestration.job_groups.origin',
    defaultMessage: 'Job Groups',
  },
  jobSchedulesOrigin: {
    id: 'orchestration.job_schedules.origin',
    defaultMessage: 'Job Schedules',
  },
  defaultOrigin: {
    id: 'orchestration.default.origin',
    defaultMessage: 'Orchestration Overview',
  },

  jobDeleteModalHeadline: {
    id: 'orchestration.job.delete_modal.headline',
    defaultMessage: 'Delete Job',
  },
  jobDeleteModalDescription: {
    id: 'orchestration.job.delete_modal.description',
    defaultMessage:
      'You are about to delete this job. Please confirm that you are sure.',
  },
  jobGroupDeleteModalHeadline: {
    id: 'orchestration.job_group.delete_modal.headline',
    defaultMessage: 'Delete Job Group',
  },
  jobGroupDeleteModalDescription: {
    id: 'orchestration.job_group.delete_modal.description',
    defaultMessage:
      'You are about to delete this Job Group. Please confirm that you are sure.',
  },
  jobScheduleDeleteModalHeadline: {
    id: 'orchestration.job_schedule.delete_modal.headline',
    defaultMessage: 'Delete Job Schedule',
  },
  jobScheduleDeleteModalDescription: {
    id: 'orchestration.job_schedule.delete_modal.description',
    defaultMessage:
      'You are about to delete this Job Schedule. Please confirm that you are sure.',
  },
  jobGroupCancel: {
    id: 'orchestration.job_group.cancel',
    defaultMessage: 'Cancel Job Group',
  },
  jobGroupRetryFailed: {
    id: 'orchestration.job_group.retry_failed',
    defaultMessage: 'Retry failed Job Group',
  },
  jobGroupRun: {
    id: 'orchestration.job_group.run',
    defaultMessage: 'Run now',
  },
  jobGroupCreateSchedule: {
    id: 'orchestration.job_group.create_schedule',
    defaultMessage: 'Create Job Schedule',
  },
  jobGroupEdit: {
    id: 'orchestration.job_group.edit',
    defaultMessage: 'Edit Job Group',
  },
  jobGroupDelete: {
    id: 'orchestration.job_group.delete',
    defaultMessage: 'Delete Job Group',
  },

  scheduleRepeating: {
    id: 'orchestration.schedule.repeating',
    defaultMessage: 'Repeating Schedule',
  },
  scheduleOneTime: {
    id: 'orchestration.schedule.one_time',
    defaultMessage: 'One-Time Schedule',
  },
  scheduleCron: {
    id: 'orchestration.schedule.cron',
    defaultMessage: 'Cron Schedule',
  },
  scheduleEdit: {
    id: 'orchestration.schedule.edit',
    defaultMessage: 'Edit Schedule',
  },
  scheduleDelete: {
    id: 'orchestration.schedule.delete',
    defaultMessage: 'Delete Schedule',
  },

  jobQueueEmptyHeadline: {
    id: 'orchestration.job_queue.empty.headline',
    defaultMessage: 'The Job Queue is currently empty',
  },
  jobQueueEmptyDescription: {
    id: 'orchestration.job_queue.empty.description',
    defaultMessage: 'Once a Job is triggered it will be enqueued here',
  },
  jobQueueAmount: {
    id: 'orchestration.job_queue.amount',
    defaultMessage: 'Job Groups in the Queue: {amount}',
  },
});
