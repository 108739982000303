import commonMessages from 'common/dist/messages/common';
import orchestrationMessages from 'common/dist/messages/orchestration';
import { ToBeRefined } from 'common/dist/types/todo_type';
import React, { FC, useEffect } from 'react';
import AceEditor from 'react-ace';
import { FiRotateCw } from 'react-icons/fi';
import { useIntl } from 'react-intl';

import BreadCrumbs from '../../atoms/bread-crumbs/BreadCrumbs';
import Busy from '../../atoms/busy/Busy';
import Button from '../../atoms/button/Button';
import styles from '../details/styles.module.scss';
import { ORCHESTRATION_ORIGINS, originName, OriginsType } from '../links';
import { orchestrationRoutes } from '../routes';

export type Props = {
  jobCode: string;
  fetchJobLogs: (jobCode: string) => void;
  origin?: OriginsType;
  data: ToBeRefined;
  loading: boolean;
  loaded: boolean;
  error: ToBeRefined;
};

const JobLogs: FC<Props> = ({
  jobCode,
  fetchJobLogs,
  origin,
  data,
  loading,
  loaded,
  error,
}) => {
  const intl = useIntl();

  useEffect(() => fetchJobLogs(jobCode), [fetchJobLogs, jobCode]);

  const renderError = () => {
    return <div className={styles.card}>{JSON.stringify(error)}</div>;
  };

  const renderLoading = () => (
    <div className={styles.card}>
      <div className={'job-details-busy-parent'}>
        <Busy isBusy={true} positionAbsolute={true} />
      </div>
    </div>
  );

  const renderHeader = () => (
    <div className={styles.detailsHeader}>
      <div className={styles.backButton}>
        <BreadCrumbs
          backToProps={[
            {
              linkTo: origin ?? ORCHESTRATION_ORIGINS.queue,
              label: intl.formatMessage(commonMessages.backTo, {
                origin: intl.formatMessage(originName(origin)),
              }),
            },
            {
              linkTo: `${orchestrationRoutes.basePath}/${orchestrationRoutes.jobDetails.path}/${jobCode}`,
              label: intl.formatMessage(commonMessages.backTo, {
                origin: intl.formatMessage(
                  orchestrationMessages.jobDetailsTitle
                ),
              }),
            },
          ]}
        />
      </div>
      <div className={styles.headerButtonContainer}>
        <div className={styles.refreshIconContainer}>
          {loading ? (
            <Button
              form={'squared'}
              Icon={() => (
                <div className={styles.refreshIconSpinningParent}>
                  <FiRotateCw
                    size={16}
                    className={styles.refreshIconSpinning}
                  />
                </div>
              )}
            />
          ) : (
            <Button
              Icon={() => <FiRotateCw size={16} className={'refresh-icon'} />}
              form={'squared'}
              onClick={() => {
                fetchJobLogs(jobCode);
              }}
              title={commonMessages.refresh}
            />
          )}
        </div>
      </div>
    </div>
  );

  const renderLoaded = () => {
    const joinedLogText = data?.map((entry, i) => entry.log).join('\n');

    return (
      <div className={'job-logs'}>
        <AceEditor
          focus={false}
          width={'100%'}
          className={'job-logs-ace'}
          mode={undefined /* Unfortunately there is no 'bash' */}
          theme='tomorrow'
          onChange={() => {}}
          showGutter
          highlightActiveLine
          value={joinedLogText || 'There are no Logs yet ...'}
          fontSize={14}
          setOptions={{
            maxLines: Infinity,
            enableBasicAutocompletion: false,
            enableLiveAutocompletion: false,
            enableSnippets: false,
            showLineNumbers: true,
            tabSize: 2,
          }}
          editorProps={{ $blockScrolling: Infinity }}
          readOnly={true}
        />
      </div>
    );
  };

  const renderContent = () => {
    if (data) return renderLoaded();
    else if (loading) return renderLoading();
    else if (error) return renderError();
    return <div />;
  };

  return (
    <div className={'Orchestration--content Orchestration--JobDetails'}>
      {renderHeader()}
      <div className={styles.item}>
        <span className={styles.headline}>Job Logs</span>
        {renderContent()}
      </div>
    </div>
  );
};

export default JobLogs;
