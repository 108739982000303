import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import { FiTrash2 } from 'react-icons/fi';

import { variableOptions } from './variableOptions';
import { VARIABLE_TYPES } from './variableTypes';
import vars from '../../../../../../../../../../../scss/base/var.module.scss';
import DropdownSelectInput from '../../../../../../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import DefaultIcon from '../../settings-dialog/DefaultElementIcon';
import { variableShape } from '../elements/_interface/P3InputElementShape';

export default class VariableCard extends Component {
  renderValue(variable) {
    if (!variable || !variable.parsedValue || !variable.type) return '';
    const { parsedValue } = variable;
    switch (variable.type) {
      case VARIABLE_TYPES.LIST_OF_NUMBER: // fall-through intended
      case VARIABLE_TYPES.LIST_OF_STRING: // fall-through intended
      case VARIABLE_TYPES.JSON:
        return JSON.stringify(parsedValue);
      default:
        return parsedValue.toString(); // .toString() to be very sure no object is tried to be rendered
    }
  }

  render() {
    const { variable, path, cell, removeVariable, updateVariable } = this.props;
    const {
      location: { search },
    } = this.props;
    const queryParameter = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });

    return (
      <div className={'variable-card'}>
        <div className={'variable-card-buttons'}>
          <div className={'variable-icon-parent'}>
            {' '}
            {/* The naming of this class is not optimal, but like this the CSS can be reused*/}
            <DefaultIcon
              height={24}
              width={24}
              elementName={'Variable'}
              elementType={'variable'}
              iconColor={vars.colorSecondary}
            />
          </div>
          <div
            title={'Remove Variable'}
            className={'workbench-button'}
            onClick={() => removeVariable(path, cell.id, variable.id)}
          >
            <FiTrash2 className={'icon'} size={'20px'} />
          </div>
        </div>
        <div className={'variable-card-fields'}>
          <div className={'variable-name-parent'}>
            <input
              type={'text'}
              onChange={(v) =>
                updateVariable(
                  path,
                  cell.id,
                  variable,
                  { name: v.target.value },
                  queryParameter
                )
              }
              value={variable.name}
              placeholder={'Name of the variable to use'}
            />
          </div>
          <div className={'variable-type-parent'}>
            <DropdownSelectInput
              id={variable.id}
              name={variable.id}
              options={variableOptions}
              placeholder={{
                id: 'no-id',
                defaultMessage: 'Type of the variable',
              }}
              value={variableOptions.find(
                (o) => o.value === (variable.type || 'string')
              )}
              onChange={(option) =>
                updateVariable(
                  path,
                  cell.id,
                  variable,
                  { type: option.value },
                  queryParameter
                )
              }
              openOnFocus
              clearable={false}
              menuPosition={'absolute'}
            />
          </div>
          {variable.parsedValue && !variable.error && (
            <div className={'variable-value-parent'}>
              <span>{this.renderValue(variable)}</span>
            </div>
          )}
          {variable.error && (
            <div className={'variable-error-parent'}>
              <span>{variable.error}</span>
            </div>
          )}
          {variable.type === VARIABLE_TYPES.STRING && (
            <div className={'variable-override-parent'}>
              <label className='checkbox-item'>
                <input
                  className='mark-input'
                  type='checkbox'
                  checked={variable.override}
                  onChange={(v) =>
                    updateVariable(
                      path,
                      cell.id,
                      variable,
                      { override: v.target.checked },
                      queryParameter
                    )
                  }
                />
                <span className='mark' />
              </label>
              <span>Override from URL</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

VariableCard.propTypes = {
  path: PropTypes.string,
  cell: PropTypes.object,
  variable: variableShape,
  removeVariable: PropTypes.func.isRequired,
  updateVariable: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};
