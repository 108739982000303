import { ToBeRefined } from '../types/todo_type';
import { MessageDescriptor } from 'react-intl';

export const weekDaysMap = {
  0: 'scheduleSelect.sunday',
  1: 'scheduleSelect.monday',
  2: 'scheduleSelect.tuesday',
  3: 'scheduleSelect.wednesday',
  4: 'scheduleSelect.thursday',
  5: 'scheduleSelect.friday',
  6: 'scheduleSelect.saturday',
};

export const weekDays = Object.values(weekDaysMap);

export const monthDaysMap = {
  1: 'scheduleSelect.1st',
  2: 'scheduleSelect.2nd',
  3: 'scheduleSelect.3rd',
  4: 'scheduleSelect.4th',
  5: 'scheduleSelect.5th',
  6: 'scheduleSelect.6th',
  7: 'scheduleSelect.7th',
  8: 'scheduleSelect.8th',
  9: 'scheduleSelect.9th',
  10: 'scheduleSelect.10th',
  11: 'scheduleSelect.11th',
  12: 'scheduleSelect.12th',
  13: 'scheduleSelect.13th',
  14: 'scheduleSelect.14th',
  15: 'scheduleSelect.15th',
  16: 'scheduleSelect.16th',
  17: 'scheduleSelect.17th',
  18: 'scheduleSelect.18th',
  19: 'scheduleSelect.19th',
  20: 'scheduleSelect.20th',
  21: 'scheduleSelect.21th',
  22: 'scheduleSelect.22th',
  23: 'scheduleSelect.23th',
  24: 'scheduleSelect.24th',
  25: 'scheduleSelect.25th',
  26: 'scheduleSelect.26th',
  27: 'scheduleSelect.27th',
  28: 'scheduleSelect.28th',
  29: 'scheduleSelect.29th',
  30: 'scheduleSelect.30th',
  31: 'scheduleSelect.31st',
};

export type Period = {
  id: 'hourly' | 'daily' | 'weekly' | 'monthly';
  label: string;
  hasValue: boolean;
  labelValue?: MessageDescriptor;
  hasOptions: boolean;
  labelOptions?: MessageDescriptor;
  hasTime: boolean;
  labelTime?: MessageDescriptor;
  valueOptions?: { type: 'number'; min: number; max: number; step: number };
  options?: { id: number; label: string }[];
  optionsPre?: string;
  valuePre?: string;
  valuePost?: string;
  timePre?: string;
};

export const schedulePeriods: Period[] = [
  {
    id: 'hourly',
    label: 'scheduleSelect.hourly',
    hasValue: true,
    labelValue: {
      id: 'no-id',
      defaultMessage: 'Minute of Hour',
    },
    hasOptions: false,
    hasTime: false,
    valueOptions: {
      type: 'number',
      min: 0,
      max: 59,
      step: 1,
    },
    valuePre: 'at',
    valuePost: 'minutes of the hour',
  },
  {
    id: 'daily',
    label: 'scheduleSelect.daily',
    hasValue: false,
    hasOptions: false,
    hasTime: true,
    labelTime: {
      id: 'no-id',
      defaultMessage: 'Time of Day',
    },
    timePre: 'at',
  },
  {
    id: 'weekly',
    label: 'scheduleSelect.weekly',
    hasValue: false,
    hasOptions: true,
    labelOptions: {
      id: 'no-id',
      defaultMessage: 'Day of Week',
    },
    hasTime: true,
    labelTime: {
      id: 'no-id',
      defaultMessage: 'Time of Day',
    },
    options: weekDays.map((label, id) => ({ id, label })),
    optionsPre: 'on',
    timePre: 'at',
  },
  {
    id: 'monthly',
    label: 'scheduleSelect.monthly',
    hasValue: false,
    hasOptions: true,
    labelOptions: {
      id: 'no-id',
      defaultMessage: 'Day of Month',
    },
    hasTime: true,
    labelTime: {
      id: 'no-id',
      defaultMessage: 'Time of Day',
    },
    options: Object.entries(monthDaysMap).map(([id, label]) => ({
      id: Number(id),
      label,
    })),
    optionsPre: 'at',
    timePre: 'at',
  },
];

export const schedulePeriodsGrouped = schedulePeriods.reduce(
  (obj: Record<string, unknown>, period): ToBeRefined => {
    obj[period.id] = period; // eslint-disable-line
    return obj;
  },
  {}
);
