import classNames from 'classnames';
import commonMessages from 'common/dist/messages/common';
import orchestrationMessages from 'common/dist/messages/orchestration';
import { JobGroupDetailsType } from 'common/dist/types/job';
import React, { FC, Fragment, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactLoading from 'react-loading';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import styles from './styles.module.scss';
import vars from '../../../../../scss/base/var.module.scss';
import { useAugurNames } from '../../../../core/api/augurs';
import { useCodeCapsuleNames } from '../../../../core/api/codeCapsules';
import { setDetailsOrigin } from '../../../../redux/modules/orchestration.jobdetails.module';
import AvatarIconContainer from '../../../atoms/avatar-icon/AvatarIcon';
import BackTo from '../../../atoms/back-to/BackTo';
import { FormattedDateTime } from '../../../atoms/formatted-date-time/FormattedDateTime';
import JobGroupTopologyChart from '../../../molecules/job-groups/job-group-topology-chart/JobGroupTopologyChart';
import { getStatusClass, speakingStatus } from '../../common/jobStatus';
import ScheduleInfo from '../../job-schedules/ScheduleInfo';
import { ORCHESTRATION_ORIGINS, originName, OriginsType } from '../../links';
import { orchestrationRoutes } from '../../routes';

export type Props = StateProps & DispatchProps;

export type StateProps = {
  jobGroupCode: string;
  jobGroupDetails: JobGroupDetailsType;
  jobGroupVariant: JobGroupVariants;
  origin?: OriginsType;
};

export type DispatchProps = {
  fetchData: (code: string) => void;
};

export const JOB_GROUP_VARIANTS = {
  jobHistory: 'jobHistory',
  jobTemplate: 'jobTemplate',
  jobSchedule: 'jobSchedule',
} as const;
type JobGroupVariants = typeof JOB_GROUP_VARIANTS[keyof typeof JOB_GROUP_VARIANTS];

const fieldsToDisplay = {
  [JOB_GROUP_VARIANTS.jobHistory]: [
    'status',
    'name',
    'description',
    'addedToPreQueue',
    'createdBy',
    'finished',
    'jobHistoryCode',
  ],
  [JOB_GROUP_VARIANTS.jobTemplate]: [
    'name',
    'description',
    'addedToPreQueue',
    'createdBy',
    'jobTemplateCode',
  ],
  [JOB_GROUP_VARIANTS.jobSchedule]: [
    'name',
    'description',
    'schedule',
    'created',
    'createdBy',
    'jobScheduleCode',
  ],
};

const fieldNames = {
  status: 'Status',
  priority: 'Priority',
  name: 'Name',
  description: 'Description',
  addedToPreQueue: 'Added to Queue',
  created: 'Created',
  createdBy: 'Created by',
  finished: 'Finished',
  schedule: 'Schedule',
  jobHistoryCode: 'Job History Code',
  jobTemplateCode: 'Job Template Code',
  jobScheduleCode: 'Job Schedule Code',
};

function renderValue(data, key) {
  switch (key) {
    case 'status':
      return speakingStatus(data[key]);
    case 'schedule':
      return renderSchedule(data[key]);
    case 'addedToPreQueue':
    case 'created':
    case 'finished':
    case 'scheduledFor':
      return renderDate(data[key]);
    case 'createdBy':
      return renderAvatar(data[key]);
    default:
      return renderAny(data[key]);
  }
}

function renderAny(obj) {
  if (!obj) {
    return (
      <td className={styles.valueNoValue}>
        <i>
          <FormattedMessage {...orchestrationMessages.detailsValueNoValue} />
        </i>
      </td>
    );
  } else if (typeof obj === 'string') {
    return JSON.stringify(obj).slice(1, -1);
  } else {
    return JSON.stringify(obj);
  }
}

function renderSchedule(obj) {
  if (!obj) return renderAny(obj);

  return (
    <ScheduleInfo
      trigger={obj.trigger}
      trigTimed={obj.trigTimed}
      trigDelayed={obj.trigDelayed}
      trigCron={obj.trigCron}
    />
  );
}

function renderDate(obj) {
  if (!obj) return renderAny(obj);

  const date = new Date(obj);
  return <FormattedDateTime date={date} dateFirst />;
}

function renderAvatar(obj) {
  if (!obj) return renderAny(obj);

  return <AvatarIconContainer userId={obj} />;
}

const JobGroupDetails: FC<Props> = ({
  jobGroupCode,
  jobGroupDetails,
  jobGroupVariant,
  fetchData,
  origin,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => fetchData(jobGroupCode), [fetchData, jobGroupCode]);

  const { data: augurNames } = useAugurNames();
  const { data: codeCapsuleNames } = useCodeCapsuleNames();

  if (!jobGroupDetails)
    return (
      <ReactLoading
        className={'busy'}
        type={'cylon'}
        color={vars.colorPrimary}
      />
    );

  const renderHeader = () => (
    <div className={styles.detailsHeader}>
      <div className={styles.backButton}>
        <BackTo
          linkTo={origin ?? ORCHESTRATION_ORIGINS.queue}
          label={intl.formatMessage(commonMessages.backTo, {
            origin: intl.formatMessage(originName(origin)),
          })}
        />
      </div>
    </div>
  );

  const fields = fieldsToDisplay[jobGroupVariant];

  let onJobGroupClick = undefined;
  if (jobGroupVariant === JOB_GROUP_VARIANTS.jobHistory) {
    onJobGroupClick = (jobCode) => {
      history.push(
        `${orchestrationRoutes.basePath}/${orchestrationRoutes.jobDetails.path}/${jobCode}`
      );
      dispatch(setDetailsOrigin(origin));
    };
  }

  return (
    <div className={styles.contentContainer}>
      {renderHeader()}
      <div className={styles.item}>
        <span className={styles.headline}>
          <FormattedMessage {...orchestrationMessages.jobGroupDetailsTitle} />
        </span>
        <div className={styles.card}>
          <Fragment>
            {fields.map((key) => (
              <div className={styles.row} key={key}>
                <span className={styles.title}>
                  {fieldNames[key] ? fieldNames[key] : key}
                </span>
                <div
                  className={
                    key === 'status'
                      ? `${styles.statusValue} ${getStatusClass(
                          jobGroupDetails[key]
                        )}`
                      : styles.value
                  }
                >
                  {renderValue(
                    {
                      ...jobGroupDetails,
                      [`${jobGroupVariant}Code`]: jobGroupCode,
                    },
                    key
                  )}
                </div>
              </div>
            ))}
          </Fragment>
        </div>
      </div>
      <div className={styles.item}>
        <span className={styles.headline}>
          <FormattedMessage
            {...orchestrationMessages.jobGroupDetailsTopologyTitle}
          />
        </span>
        <div className={classNames(styles.card, styles.cardGrey)}>
          <JobGroupTopologyChart
            jobs={jobGroupDetails.jobs}
            jobGroupTopology={jobGroupDetails.jobGroupTopology}
            showJobStatus={jobGroupVariant === JOB_GROUP_VARIANTS.jobHistory}
            augurNames={augurNames || {}}
            codeCapsuleNames={codeCapsuleNames || {}}
            slim={false}
            onClick={onJobGroupClick}
          />
        </div>
      </div>
    </div>
  );
};

export default JobGroupDetails;
